<template>
    <div class="resumePage">
        <div class="tabs">
            <ul>
                <li @click="changeKey(4)" :class="key==4?'active':''" v-if="isHr==1">总览</li>
                <li @click="changeKey(0)" :class="key==0?'active':''">招聘需求</li>
                <li @click="changeKey(5)" :class="key==5?'active':''">招聘进展</li>
                <li @click="changeKey(1)" :class="key==1?'active':''" v-if="isHr==1">候选人</li>
                <li @click="changeKey(2)" :class="key==2?'active':''" v-if="isHr==1">面试安排</li>
                <li @click="changeKey(3)" :class="key==3?'active':''" v-if="isHr==1">人才库</li>
            </ul>
        </div>
        <div class="container">
            <needsContent v-if="key==0"></needsContent>
            <resume v-if="key==1"></resume>
            <interviewList v-if="key==2"></interviewList>
            <resumePersonnel v-if="key==3"></resumePersonnel>
            <hrOverview v-if="key==4"></hrOverview>
            <resumeProgress v-if="key==5"></resumeProgress>
        </div>
    </div>
</template>

<script>
    import needsContent from 'pages/Hr/needsContent';
    import resume from 'pages/Hr/resume';
    import interviewList from 'pages/Hr/interviewList';
    import resumePersonnel from 'pages/Hr/resumePersonnel';
    import hrOverview from 'pages/Hr/hrOverview';
    import resumeProgress from 'pages/Hr/resumeProgress';
    export default {
        name: "resumePage" ,
        components: {
            needsContent,resume,interviewList,resumePersonnel,hrOverview,resumeProgress
        },
        data(){
            return {
                key: 0,
                isHr:2,
            }
        },
        created(){
          this.loadData();
        },
        methods: {
            loadData(){
                let self = this;
                this.$axios.get(129423,{},res=>{
                    if(res.data.code==0){
                        self.isHr = res.data.data.isHr;
                        if(res.data.data.isHr == 1){
                            self.key = 4;
                        }else{
                            self.key = 0;
                        }

                    }
                })
            },
            changeKey(key){
                this.key = key;
            }
        }
    }
</script>

<style scoped lang="less">
    .resumePage{
        //height: 100%;
    }
    .tabs{
        border-bottom: 1px solid #CCD1DC;
        border-left: 1px solid #CCD1DC;
        border-top-left-radius: 5px;
        margin-bottom: 10px;
        ul{
            li{
                display: inline-block;
                width: 85px;
                padding: 6px 0;
                text-align: center;
                border: 1px solid #CCD1DC;
                border-bottom: none;
                cursor: pointer;
                border-left: 0;
                &:first-child{
                    border-top-left-radius: 5px;
                }
                &:last-child{
                    border-top-left-radius: 0;
                    border-top-right-radius: 5px;
                }
            }
            .active{
                background: #1890FF;
                color: #fff;
                border: 1px solid #1890FF;
            }
        }
    }

</style>